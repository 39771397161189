<template>
    <div class="">

    <!-- Whatsapp -->
    <Whatsappbtn/>
        
    <!-- Header -->
    <TheHeader/>

    <!-- Banner -->
    <TheBanner hide-enquiry="true"/>

    <div class="container py-8 mx-auto text-center">
        <h1 class="pb-6 text-2xl font-semibold md:text-4xl">Message Sending Successful!</h1>
        <p class="px-4 text-base md:px-0 open-reg">
            Thank you for your kind interest in Wei Chu Seafood Supply Trading S/B. We will get back to you as soon as possible.
        </p>
            <router-link to="/" class="block w-48 mx-auto">
            <p class="block py-2 my-5 text-white bg-blue-400 rounded-lg open-reg">Back To Home</p>
        </router-link>
    </div>

    <!-- Copyright -->
    <Thecopyright/>

    </div>
</template>

<script>
import Whatsappbtn from "@/components/Whatsappbtn.vue"
import TheHeader from "@/components/TheHeader.vue";
import TheBanner from "@/components/TheBanner.vue";
import Thecopyright from "@/components/Thecopyright.vue";

export default {
    components: {
        Whatsappbtn,
        TheHeader,
        TheBanner,
        Thecopyright,
    },
}
</script>

<style>

</style>